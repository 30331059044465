var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "regional-class-container" },
    [
      _c(
        "Drawer",
        {
          attrs: {
            closable: "",
            "is-mask": false,
            visible: _vm.visible,
            size: "small",
            title: "添加城市",
          },
          on: {
            close: function ($event) {
              _vm.visible = false
            },
            onSubmit: _vm.onSave,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "a-form-model",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c("a-form-model-item", { attrs: { label: "已选择城市" } }, [
                    _c(
                      "div",
                      { staticClass: "area-box" },
                      _vm._l(_vm.form.areaList, function (area, i) {
                        return _c(
                          "span",
                          { key: area.areaCode, staticClass: "area" },
                          [
                            _vm._v(_vm._s(area.areaName) + " "),
                            _c(
                              "svg",
                              {
                                staticClass: "iconpark-icon delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.form.areaList.splice(i, 1)
                                  },
                                },
                              },
                              [_c("use", { attrs: { href: "#close-small" } })]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "新增城市" } },
                    [
                      _vm.visible
                        ? _c("SelectArea", {
                            ref: "selectArea",
                            attrs: {
                              "province-width": 100,
                              "city-width": 100,
                              "area-width": 100,
                              "area-show": false,
                              "government-auto": true,
                            },
                            on: { getStrandValue: _vm.getStrandValue },
                            model: {
                              value: _vm.form.areaId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "areaId", $$v)
                              },
                              expression: "form.areaId",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }